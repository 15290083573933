import Vue from 'vue'
import VueRouter from 'vue-router'

import Pay  from '../views/pay/pay.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'querybill',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/pay/querybill.vue')
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path:'/pay',
    name:'Pay',
    component:Pay

  },
  {
    path: '/orderlist',
    name:'Orderlist',
    component:()=>import('../views/pay/orderlist')
  },
  {
    path: '/order/payment/:status',
    name: 'paymentStatus',
    component: () => import('@/views/order/payment-status'),
    props: true
  }
]

const router = new VueRouter({
  //mode: 'history', // 去掉url中的#
  scrollBehavior: () => ({ y: 0 }),
  routes
})
/* 路由发生变化修改页面title */
router.beforeEach((to, from, next) => {
  //if (to.meta.title) {
    document.title = "方安物业收费系统"
 // }
  next()
})
export default router
