import request from "@/utils/request";

/**
 * 获取code
 * @returns {AxiosPromise}
 */
export  function getUrlCode(){
    return request({
        url: '/getUrlCode.ashx',
        method: 'get',

    })
}
/**
 * 获取openid
 * @param query
 * @returns {AxiosPromise}
 */
export  function  getOpenid(query){
    return request({
        url: '/getOpenid.ashx',
        method: 'get',
        params: query

    })
}

/**
 * 统一支付下订单
 * @param data
 * @returns {AxiosPromise}
 */
export function getUnifiedorderResult(data){
    return request({
        url: '/payorderhandler.ashx',
        method: 'get',
        params: data
    })
}

/**
 * 根据用户户号或者电笔表号获取用户信息明细
 * @param query
 * @returns {AxiosPromise}
 */
export function queryDoorNoList(query){
    return request({
        url: '/getElecricOrderHandler.ashx',
        method: 'get',
        params: query

    })
}
/**
 * 根据用户户号或者电笔表号获取电表充扣明细
 * @param query
 * @returns {AxiosPromise}
 */
export function queryDoorElectricNoList(query){
    return request({
        url: '/getElecricOrderLogHandler.ashx',
        method: 'get',
        params: query

    })
}
